// EmailVerificationMessage.js
import React, { useEffect, useState } from 'react';
import { getAuth, applyActionCode } from 'firebase/auth';

const EmailVerificationMessage = ({ actionCode }) => {
    const [message, setMessage] = useState('Verifying your email...');

    useEffect(() => {
        const verifyEmail = async () => {
            const auth = getAuth();
            try {
                await applyActionCode(auth, actionCode);
                setMessage('Email has been verified successfully.');
            } catch (error) {
                setMessage(`Error: ${error.message}`);
            }
        };

        verifyEmail();
    }, [actionCode]);

    return (
        <div className="container">
            <h1>Email Verification</h1>
            <p>{message}</p>
        </div>
    );
};

export default EmailVerificationMessage;