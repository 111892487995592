import React from 'react'

export default function Navbar() {
  return (
    <div className="shadow-xl mb-20 authNavbar text-center">
        <span className="ae-logo">æ</span>&nbsp;&nbsp;
        <span className="apersu-logo">APERSU</span>
        <span className="apersu-logo"><b>ESTIMATING</b></span>
    </div>
  )
}
