// App.js
import './App.css';
import './output.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FirebaseAuthActions from './pages/firebaseAuthActions';
import Navbar from './pages/navbar';

function App() {
  return (<>
  <Navbar/>
    <Router>
      <Routes>
        <Route path="/__/auth" element={<FirebaseAuthActions />} />
      
      </Routes>
    </Router>
    </>
  );
}

export default App;
