// RecoverEmailForm.js
import React, { useEffect, useState } from 'react';
import { getAuth, checkActionCode, applyActionCode } from 'firebase/auth';

const RecoverEmailForm = ({ actionCode }) => {
    const [message, setMessage] = useState('Reverting your email...');

    useEffect(() => {
        const recoverEmail = async () => {
            const auth = getAuth();
            try {
                const info = await checkActionCode(auth, actionCode);
                await applyActionCode(auth, actionCode);
                setMessage(`Email has been reverted to ${info.data.email}.`);
            } catch (error) {
                setMessage(`Error: ${error.message}`);
            }
        };

        recoverEmail();
    }, [actionCode]);

    return (
        <div className="container">
            <h1>Recover Email</h1>
            <p>{message}</p>
        </div>
    );
};

export default RecoverEmailForm;