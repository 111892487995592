// PasswordResetForm.js
import React, { useState } from 'react';
import { getAuth, confirmPasswordReset } from 'firebase/auth';
import { CheckCircle } from '@phosphor-icons/react';

const PasswordResetForm = ({ actionCode }) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');

    const handlePasswordReset = async (event) => {
        event.preventDefault();
        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match.');
            return;
        }
        const auth = getAuth();
        try {
            await confirmPasswordReset(auth, actionCode, newPassword);
            setMessage('Password has been reset successfully.');
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }
    };

    return (
        <div className="container mx-auto max-w-md mt-10">
            <h1 className="text-2xl font-bold mb-5">Set a new password for</h1>
            <form onSubmit={handlePasswordReset} className="space-y-4">
                <div>
                    <label htmlFor="newPassword" className="block mb-2">New Password</label>
                    <input
                        id="newPassword"
                        type="password"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        className="input input-bordered w-full"
                    />
                </div>
                <div>
                    <label htmlFor="confirmPassword" className="block mb-2">Confirm Password</label>
                    <input
                        id="confirmPassword"
                        type="password"
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        className="input input-bordered w-full"
                    />
                </div>
                <button type="submit" className="btn btn-primary w-full"><CheckCircle size={30}/>Reset Password</button>
            </form>
            {message && <p className="mt-4 text-center">{message}</p>}
        </div>
    );
};

export default PasswordResetForm;