// FirebaseAuthActions.js
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PasswordResetForm from './cases/PasswordResetForm';
import EmailVerificationMessage from './cases/EmailVerificationMessage';
import RecoverEmailForm from './cases/RecoverEmailForm';
import ChangeEmailForm from './cases/ChangeEmailForm';

const FirebaseAuthActions = () => {
    const [searchParams] = useSearchParams();
    const [mode, setMode] = useState(null);
    const [actionCode, setActionCode] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const modeParam = searchParams.get('mode');
        const actionCodeParam = searchParams.get('oobCode');

        if (!modeParam || !actionCodeParam) {
            setError('Invalid or missing parameters.');
            return;
        }

        setMode(modeParam);
        setActionCode(actionCodeParam);
    }, [searchParams]);

    if (error) {
        return <div className="container"><p>{error}</p></div>;
    }

    switch (mode) {
        case 'resetPassword':
            return <PasswordResetForm actionCode={actionCode} />;
        case 'verifyEmail':
            return <EmailVerificationMessage actionCode={actionCode} />;
        case 'recoverEmail':
            return <RecoverEmailForm actionCode={actionCode} />;
        case 'verifyAndChangeEmail':
            return <ChangeEmailForm actionCode={actionCode} />;
        default:
            return <div className="container"><p>Unsupported mode.</p></div>;
    }
};

export default FirebaseAuthActions;